/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react"
import { store } from "../../app/store"
import * as Mui from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { createTheme } from "@mui/material/styles"
import {
  deleteUser,
  editUser,
  getUserList,
  selectAllUsers,
} from "../../containers/Login/userSlice"
import { useEffect } from "react"
import "./index.scss"
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables"
import AddUserForm from "./userForm"
import { Lead, User } from "../../types"
import io from "socket.io-client"
import { Snackbar } from "@mui/material"
import * as Icons from "@mui/icons-material"
type AppDispatch = typeof store.dispatch
const theme = createTheme()
const DrawerHeader = Mui.styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}))
interface UserProp {
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  maxDailyLeadClaim: string
  smsProviderId: string
  password: string
  isSuperAdmin: boolean
  lastHistory?: {
    message: string
    createdAt: string
    lead?: Lead
  }
}
export default function Users() {
  const [fullWidth] = useState(true)
  const [maxWidth] = useState<Mui.DialogProps["maxWidth"]>("md")
  const usersFromStore = useSelector(selectAllUsers)
  const [users, setUsers] = useState(usersFromStore)
  const [loading, setLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false) // Snackbar'ın açık/kapalı durumu
  const [snackbarMessage, setSnackbarMessage] = useState("") // Snackbar mesajı
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  ) // Snackbar türü (başarı/hata)
  const [data, setData] = useState<any[]>([])
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    if (!users) {
      dispatch(getUserList({ includeLastHistory: true }))
    }

    // Socket.IO bağlantısı kur
    const socket = io(process.env.REACT_APP_API_URL, {
      path: "/socket.io",
    })

    socket.on("userLoggedIn", () => {
      dispatch(getUserList({ includeLastHistory: true }))
    })

    socket.on("userLoggedOut", () => {
      dispatch(getUserList({ includeLastHistory: true }))
    })

    // Cleanup on unmount
    return () => {
      socket.disconnect()
    }
  }, [dispatch, users])
  const [newUser, setNewUser] = useState({})
  const [openNewUser, setOpenNewUser] = useState(false)
  const [openDeleteUser, setOpenDeleteUser] = useState(false)
  const [deletedUser, setDeletedUser] = useState({})
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false)
  const [selectedUserHistory, setSelectedUserHistory] =
    useState<UserProp | null>(null)

  const handleOpenHistoryDialog = (dataIndex: number) => {
    const user = users[dataIndex]
    setSelectedUserHistory(user)
    setOpenHistoryDialog(true)
  }

  const handleCloseHistoryDialog = () => {
    setOpenHistoryDialog(false)
    setSelectedUserHistory(null)
  }
  const handleOpenNewUser = () => {
    setNewUser({})
    setOpenNewUser(true)
  }
  const handleCloseNewUser = () => {
    setOpenNewUser(false)
  }
  const handleEditUser = (user: User) => {
    setNewUser(user)
    setOpenNewUser(true)
  }

  const handleOpenDeleteUser = (user: User) => {
    setDeletedUser(user)
    setOpenDeleteUser(true)
  }
  const handleCloseDeleteUser = () => {
    setOpenDeleteUser(false)
  }
  const handleSubmitDelete = () => {
    dispatch(deleteUser(deletedUser))
    handleCloseDeleteUser()
  }

  useEffect(() => {
    setUsers(usersFromStore) // Kullanıcı listesi değiştiğinde güncelle
  }, [usersFromStore])

  const handleUserUpdated = () => {
    setSnackbarMessage("User updated successfully!")
    setSnackbarSeverity("success")
    setOpenSnackbar(true)
    dispatch(getUserList({ includeLastHistory: true }))
    setOpenNewUser(false)
  }
  const handleMaxDailyClaimChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    dataIndex: number
  ) => {
    const updatedValue = event.target.value // Değeri input veya textarea'dan al
    const updatedUsers = [...users]
    updatedUsers[dataIndex] = {
      ...updatedUsers[dataIndex],
      maxDailyLeadClaim: updatedValue,
      isChanged: true,
    }
    setUsers(updatedUsers) // Güncellenmiş listeyi state'e set et
  }

  const handleUpdateClick = async (dataIndex: number) => {
    setLoading(true) // Loading başlasın
    const updatedUser: UserProp = {
      ...users[dataIndex],
    }

    try {
      await dispatch(editUser(updatedUser)).unwrap() // Güncelleme isteğini gönder
      setSnackbarMessage("User updated successfully!")
      setSnackbarSeverity("success")
      dispatch(getUserList({ includeLastHistory: true })) // Redux'tan yeni listeyi çek
    } catch (error) {
      setSnackbarMessage("Failed to update user!")
      setSnackbarSeverity("error")
    } finally {
      setOpenSnackbar(true) // Snackbar'ı aç
      setLoading(false) // İşlem bitince loading sona ersin
    }
  }
  const columns = [
    {
      name: "History",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          const historyTime = users[dataIndex]?.lastHistory?.createdAt
            ? new Date(users[dataIndex].lastHistory.createdAt).toLocaleString()
            : "No history"

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Mui.IconButton
                aria-label="history"
                onClick={() => handleOpenHistoryDialog(dataIndex)}
              >
                <Icons.History />
              </Mui.IconButton>
              <span style={{ marginLeft: "10px" }}>{historyTime}</span>
            </div>
          )
        },
      },
    },
    {
      name: "FirstName  LastName",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          const FullName =
            users[dataIndex]?.firstName + " " + users[dataIndex]?.lastName
          const emailAddress = users[dataIndex]?.emailAddress
          const phoneNumber = users[dataIndex]?.phoneNumber
          return (
            <div>
              <div>
                <b>{FullName}</b>
              </div>
              <div>{emailAddress}</div>
              <div>{phoneNumber}</div>
            </div>
          )
        },
      },
    },
    {
      name: "Max Daily Claim",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => {
          const maxDailyClaim = users[dataIndex]?.maxDailyLeadClaim || ""
          const isChanged = users[dataIndex]?.isChanged || false

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Mui.TextField
                value={maxDailyClaim}
                onChange={(event) =>
                  handleMaxDailyClaimChange(event, dataIndex)
                }
                variant="outlined"
                size="small"
                fullWidth
                style={{ width: "70px", fontSize: "12px" }}
              />
              <Mui.Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleUpdateClick(dataIndex)}
                disabled={!isChanged || loading} // Değişiklik yoksa veya loading varsa buton pasif
                style={{ marginLeft: "10px" }}
              >
                {loading ? "Updating..." : "Update"} {/* Loading gösterimi */}
              </Mui.Button>
            </div>
          )
        },
      },
    },
    { name: "Admin", options: { filter: false } },
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              size="small"
              onClick={() => handleEditUser(users[dataIndex])}
            >
              Edit
            </Mui.Button>
          )
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <Mui.Button
              variant="contained"
              onClick={() => handleOpenDeleteUser(users[dataIndex])}
              size="small"
              color="error"
            >
              Delete
            </Mui.Button>
          )
        },
      },
    },
  ]
  const options: MUIDataTableOptions = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "Is Active",
      direction: "desc",
    },
  }
  /*TODO ask better way */
  useEffect(() => {
    if (users) {
      const updatedData = users.map((result: any) => [
        result.isActive,
        result.maxDailyLeadClaim ? result.maxDailyLeadClaim : "",
        result.isSuperAdmin ? "yes" : "no",
      ])
      setData(updatedData)
    }
  }, [users])

  return (
    <>
      <Mui.ThemeProvider theme={theme}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }} // Sağ üst köşe ayarı
        >
          <Mui.Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity} // Başarı veya hata durumuna göre ayarlanır
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Mui.Alert>
        </Snackbar>
        <DrawerHeader />
        <Mui.Box sx={{ "& button": { m: 1 } }}>
          <Mui.Button
            type="button"
            variant="contained"
            className="button"
            onClick={() => {
              handleOpenNewUser()
            }}
          >
            Add New User
          </Mui.Button>
        </Mui.Box>
        <MUIDataTable
          title={"Employee List"}
          data={data}
          columns={columns}
          options={options}
        />
      </Mui.ThemeProvider>
      <Mui.Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openHistoryDialog}
        onClose={handleCloseHistoryDialog}
      >
        <Mui.DialogTitle>User Last History</Mui.DialogTitle>
        <Mui.DialogContent>
          {selectedUserHistory?.lastHistory ? (
            <div>
              <Mui.Typography variant="h6">
                {selectedUserHistory.lastHistory.message}
              </Mui.Typography>
              <Mui.Typography variant="body2" color="textSecondary">
                {new Date(
                  selectedUserHistory.lastHistory.createdAt
                ).toLocaleString()}
              </Mui.Typography>
              {selectedUserHistory.lastHistory &&
              selectedUserHistory.lastHistory.lead ? (
                <div>
                  <p>
                    First Name: {selectedUserHistory.lastHistory.lead.firstName}
                  </p>
                  <p>
                    Last Name: {selectedUserHistory.lastHistory.lead.lastName}
                  </p>
                  <p>
                    Phone Number:{" "}
                    {selectedUserHistory.lastHistory.lead.phoneNumber}
                  </p>
                  <p>Email: {selectedUserHistory.lastHistory.lead.email}</p>
                  <p>Status: {selectedUserHistory.lastHistory.lead.status}</p>
                </div>
              ) : (
                <p>No lead history available.</p>
              )}
            </div>
          ) : (
            <Mui.Typography variant="body2">
              No history available.
            </Mui.Typography>
          )}
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseHistoryDialog}>Close</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      <Mui.Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openNewUser}
        onClose={handleCloseNewUser}
      >
        <Mui.DialogTitle>User Settings</Mui.DialogTitle>
        <Mui.DialogContent>
          <AddUserForm user={newUser} onUserSaved={handleUserUpdated} />
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseNewUser}>Close</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
      <Mui.Dialog
        fullWidth={fullWidth}
        open={openDeleteUser}
        onClose={handleCloseDeleteUser}
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {`Do you want to delete user?`}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">
            If you submit this from user will be delete do you agree ?
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button onClick={handleCloseDeleteUser}>Close</Mui.Button>
          <Mui.Button onClick={handleSubmitDelete}>Submit</Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  )
}
