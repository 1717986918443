/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { RouterProvider } from "react-router-dom"
import route from "./route"
import { store } from "./app/store"
import { Provider } from "react-redux"
import CssBaseline from "@mui/material/CssBaseline"
import "./index.scss"
import SocketProvider from "./components/SocketProvider"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

if (process.env.NODE_ENV !== "production") {
  console.error = function () {}
}

root.render(
  <Provider store={store}>
    <SocketProvider>
      <CssBaseline />
      <RouterProvider router={route} />
    </SocketProvider>
  </Provider>
)

reportWebVitals()
